export const AdvisorsList = [
  {
      "id": 1,
      "name": 'Anish Behuray',
      designation: 'Core Head',
      fb: 'https://www.facebook.com/profile.php?id=100075143666949',
      instagram: '',
      linkedin:'https://www.linkedin.com/in/anish-behuray-10a672222/',
      pfp: '/assets/images/anish.jpg',
    },
    {
      id: 2,
      name: 'Jainish Solanki',
      designation: 'Tech Head',
      fb: 'https://www.facebook.com/profile.php?id=100073994914255',
      instagram: ['frontend', 'ui/ux', 'design'],
      linkedin:'https://www.linkedin.com/in/jainish-solanki-909557231/',
      pfp: '/assets/images/jainish.jpg',
    },
    {
      id: 3,
      name: 'JSR Sujit',
      designation: 'Executive Head',
      fb: 'https://www.facebook.com/profile.php?id=100009358659751',
      instagram: ['frontend', 'ui/ux', 'design'],
      linkedin:'https://www.linkedin.com/in/jsr-sujit-525625223/',
      pfp: '/assets/images/sujit.png',
    },
    {
      id: 4,
      name: 'Khushi Agrawal',
      designation: 'Design Head',
      fb: 'https://www.facebook.com/profile.php?id=100016076352026',
      instagram: ['frontend', 'ui/ux', 'design'],
      linkedin:'https://www.linkedin.com/in/khushi-agrawal-ba9407231/',
      pfp: '/assets/images/khushi.jpg',
    },
    {
      id: 5,
      name: 'Priyanshi Khetan',
      designation: 'PR/Lit Head',
      fb: 'https://www.facebook.com/priyanshi.khetan.18',
      instagram: ['frontend', 'ui/ux', 'design'],
      linkedin:'https://www.linkedin.com/in/priyanshi-khetan-1514b4223/',
      pfp: '/assets/images/priyanshi.jpg',
    },
    {
      id: 6,
      name: 'Sapharu Veena Nikhita',
      designation: 'Executive Head',
      fb: 'https://www.facebook.com/profile.php?id=100075559055537',
      instagram: ['frontend', 'ui/ux', 'design'],
      linkedin:'https://www.linkedin.com/in/sapharu-veena-nikhita-4a08a5241/',
      pfp: '/assets/images/nikhita.png',
    },
    {
      id: 7,
      name: 'Shubham Singh',
      designation: 'Core Head',
      fb: 'https://www.facebook.com/profile.php?id=100075171173256',
      instagram: ['frontend', 'ui/ux', 'design'],
      linkedin:'https://www.linkedin.com/in/shubham-singh-406772223/',
      pfp: '/assets/images/shubham.jpg',
    },
    {
      id: 8,
      name: 'Yash Kumar',
      designation: 'Tech Head',
      fb: 'https://www.facebook.com/profile.php?id=100075211103014',
      instagram: 'https://www.instagram.com/yashkr_23/',
      linkedin:'https://www.linkedin.com/in/yash-kumar-53280922b/',
      pfp: '/assets/images/yash.jpg',
    },
];