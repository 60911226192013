

export const blogList5 = [
    {
      id: 1,
      title: 'Aerospace Engineering',
      category: 'AE',
      subCategory: ['frontend', 'ui/ux', 'design'],
      description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
      authorName: 'John Doe',
      authorAvatar: '/assets/images/author.jpg',
      cover: "/assets/images/aero.png",
      medium: 'https://iitkgpacin-my.sharepoint.com/:f:/g/personal/arya_rajendra_kgpian_iitkgp_ac_in/ElmpOwIdSb9JnSG0eX8vLQUB-RE191BCA2SvE4iNG1FXaw?e=47grpp'
    },
    {
        id: 2,
        title: 'Agricultural and Food Engineering',
        category: 'AG',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/agri.png',
        medium: 'https://thekushal15.github.io/agfe-materials/'
    },
    {
        id: 3,
        title: 'Biotechnology',
        category: 'BT',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/bio.png',
        medium: 'https://drive.google.com/drive/u/5/folders/114r6XbLPUYrDxitpeOhJWWDHB-ur0Lj1'
    },
    {
        id: 4,
        title: 'Chemistry',
        category: 'CY',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/chem.png',
        medium: 'https://mega.nz/folder/wSpj3KbZ#aQSZZV-FJodbhi-mLrI0zg'
    },
    {
        id: 5,
        title: 'Chemical Engineering',
        category: 'CH',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/chemical.png',
        medium: 'https://drive.google.com/drive/folders/1x3E71XQ7jCrKt3L-gyq52j6Ue5u3ETpK'
    },
    {
        id: 6,
        title: 'Civil Engineering',
        category: 'CE',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/civil.png',
        medium: 'https://drive.google.com/drive/folders/1XdBnwhAIaz39KvHV_7qUXfFjwQGWppoN'
    },
    {
        id: 7,
        title: 'Computer Science and Engineering',
        category: 'CS',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/cs.png',
        medium: 'https://garggopal2001.github.io/cse_material/#sem03'
    },
    {
        id: 8,
        title: 'Electronics & Electrical Comm Engg',
        category: 'EC',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/ece.png',
        medium: 'https://drive.google.com/drive/folders/1IwpXmL8YGNtVK6XynMX_YMd6FPQBTwDz'
    },
    {
        id: 9,
        title: 'Electrical Engineering',
        category: 'EE',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/ee.png',
        medium: 'https://drive.google.com/drive/folders/13dgLGx0x4XDPJ8efKCnY_uCSq0fFq2vD'
    },
    {
        id: 10,
        title: 'Exploration Geophysics',
        category: 'EX',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/egeo.png',
        medium: 'https://drive.google.com/drive/folders/1aSo54MIhO1mltD6vg8eL6xYbdpm0yKzK'
    },
    {
        id: 11,
        title: 'Economics',
        category: 'HS',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/hss.png',
        medium: 'https://github.com/econkgp/econ_resources'
    },
    {
        id: 12,
        title: 'Geology and Geophysics',
        category: 'GG',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/ageo.png',
        medium: 'https://drive.google.com/drive/folders/1aSo54MIhO1mltD6vg8eL6xYbdpm0yKzK'
    },
    {
        id: 13,
        title: 'Industrial and Systems Engineering',
        category: 'IM',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/indu.png',
        medium: 'https://drive.google.com/drive/folders/1qBPTmdApfAJwoYG_t7XdX5pLlDYuYwb_'
    },
    {
        id: 14,
        title: 'Instrumentation Engineering',
        category: 'IE',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/instru.png',
        medium: 'https://drive.google.com/drive/folders/13dgLGx0x4XDPJ8efKCnY_uCSq0fFq2vD'
    },
    {
        id: 15,
        title: 'Mechanical Engineering',
        category: 'ME',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/mech.png',
        medium: 'https://drive.google.com/drive/folders/1FKvOqBjfk70Whdzd7CA0LbxPr25PNwvr'
    },
    {
        id: 16,
        title: 'Manufacturing Science and Engineering',
        category: 'MF',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/manu.png',
        medium: 'https://drive.google.com/drive/folders/1FKvOqBjfk70Whdzd7CA0LbxPr25PNwvr'
    },
    {
        id: 17,
        title: 'Mining Engineering',
        category: 'MI',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/mine.png',
        medium: 'https://iitkgpacin-my.sharepoint.com/:f:/g/personal/arkapravachunari03_kgpian_iitkgp_ac_in/ErybLlO9GYFNl2wOWoWrII0BWksHCOdqRXKb7RgVYziypg?e=Cu2lmq'
    },
    {
        id: 18,
        title: 'Mathematics',
        category: 'MA',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/mnc.png',
        medium: 'https://drive.google.com/drive/folders/1Azf3oXoTkD0q6PZHZw_19nmpeoSrmBkL'
    },
    {
        id: 19,
        title: 'Metallurgical and Materials Engineering',
        category: 'MT',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/metal.png',
        medium: 'https://drive.google.com/drive/folders/1B-ajlseWBdKy_0NHZl7hm3lOXcJMXe2j'
    },
    {
        id: 20,
        title: 'Ocean Engineering and Naval Architecture',
        category: 'NA',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/ocean.png',
        medium: 'https://oena-portal.netlify.app/years/second.html'
    },
    {
        id: 21,
        title: 'Physics',
        category: 'PH',
        subCategory: ['frontend', 'ui/ux', 'design'],
        description:'I felt at the top of the world, knowing I could learn programming and computers at the premier institute.',
        authorName: 'John Doe',
        authorAvatar: '/assets/images/author.jpg',
        cover: '/assets/images/physics.png',
        medium: 'https://drive.google.com/drive/folders/1-BSFngARCSbTdsf_GPYsgG4M7ygiJfHn'
    },
  ];
