export const blogListintern = [
  {
    id: 192,
    title: 'Texas Instruments | Sanidhya Raj',
    category: 'Software',
    company: "Texas Instruments",
    description:
      "I recommend starting earlier to ease the pressure towards the end. Regardless of when you begin, stay calm and devise your strategy",
    createdAt: 'June 16, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*7vxrBabImsrGC-oaio-Y8g.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-texas-instruments-sanidhya-raj-ca318275d839',
  },
  {
    id: 191,
    title: 'Texas Instruments | Kshitij Banerjee',
    category: 'Core',
    company: "Texas Instruments",
    description:
      "don’t lose hope. It’s a marathon, not a sprint. Keep putting your best foot forward, stay persistent, and trust in your abilities.",
    createdAt: 'June 16, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*zP_Ls4dj4-UuUTtRvYE06w.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-texas-instruments-kshitij-banerjee-e5ad6dc4a65d',
  },
  {
    id: 190,
    title: 'Google | Shelly Aggarwal',
    category: 'Software',
    company: "Google",
    description:
      "The most important part is DSA; practice as many questions as you can. Once you are comfortable solving questions of a particular difficulty level, increase the difficulty level",
    createdAt: 'June 15, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*gXErFUu1u1gcnCvylcjmhA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-google-shelly-aggarwal-4679ee5d1366',
  },
  {
    id: 189,
    title: 'Graviton Research Capital | Abhijeet Singh',
    category: 'Software',
    company: "Graviton Research Capital",
    description:
      "it’s essential to build a strong foundation in both competitive programming (CP) and core computer science fundamentals. Start early with CP",
    createdAt: 'June 14, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*NPrk4v8mLiu68J-kzGp2IQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-graviton-research-capital-abhijeet-sin-c6b9abd2b95e',
  },
  {
    id: 188,
    title: 'Accenture | Archana Satapathy',
    category: 'Consult',
    company: "Accenture",
    description:
      "be thorough with your CV, have a detailed explanation of the projects mentioned, prepare a proper introduction highlighting all essential points",
    createdAt: 'June 13, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*ACEU1g-oPAJ4dlSKj_IQbQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-accenture-archana-satapathy-c6bd52fdada7',
  },
  {
    id: 187,
    title: 'Dr Reddy | Tarun Goyal',
    category: 'Core',
    company: "Dr Reddy",
    description:
      "For the GD round, knowledge of unit operations and other basic concepts was required. This round is one of the biggest hurdles in the entire selection procedure, as more than 50% of students are eliminated at this round",
    createdAt: 'June 13, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*FHM6jSYSPcrh31QsYqzR5Q.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-dr-reddy-tarun-goyal-990cb3f7c508',
  },
  {
    id: 186,
    title: 'Google | Samay Sawal',
    category: 'Software',
    company: "Google",
    description:
      "be thorough with all the concepts of DSA, and try to solve interview bit as many times as you can alongside all the contests that GOC has conducted to date",
    createdAt: 'June 12, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*Y5d6ajD6hxpQk00m7CEijQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-google-samay-sawal-52c10d706d43',
  },
  {
    id: 185,
    title: 'Quadeye | Atishay Jain',
    category: 'Software',
    company: "Quadeye",
    description:
      "this position demands a robust understanding of data structures, algorithms, and object-oriented programming, along with the capability to write highly optimized code.",
    createdAt: 'June 12, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*4qrgiQnb3h9wXyWiWz-glA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-quadeye-atishay-jain-3f96e172ade2',
  },
  {
    id: 184,
    title: 'McKinsey & Company | Samarth Singh',
    category: 'Consultancy',
    company: "McKinsey & Company",
    description:
      "Management Consulting as a role is something that you cannot just build up within a day or a month. It’s the whole personification of how your personality is; how inquisitive, how curious you are",
    createdAt: 'June 11, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*NTTFRGOqgWeKc_Sxlm8-Lg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-mckinsey-company-samarth-singh-2ff6ba7a9cce',
  },
  {
    id: 183,
    title: 'Oracle | Aryan Kumar',
    category: 'Software',
    company: "Oracle",
    description:
      "Focus on dynamic programming and graph-related problems. Aim to solve at least 100-150 (dp + graph) LeetCode questions to become proficient.",
    createdAt: 'June 10, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*qEsjv26X2waUUQnKDeHR9A.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-oracle-aryan-kumar-84d1b13945fd',
  },
  {
    id: 182,
    title: 'EXL Services | Pravalika Das',
    category: 'Data',
    company: "EXL Services",
    description:
      "The only thing that matters is the Project/ Previous Internship in this field. It doesn’t mean one shouldn’t have any other sections in their CV but the CV should contain a minimum of 2 projects/interns",
    createdAt: 'June 10, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*Pa-wrJ1c-jk5RbktVNF4yg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-exl-services-pravalika-das-75c46299b2cd',
  },
  {
    id: 181,
    title: 'Mastercard | Khushi Agrawal',
    category: 'Software',
    company: "Mastercard",
    description:
      "It’s not just about preparation but also about staying positive and confident till the time situation is in our favour. Rejections and seeing peers land internships early can bring self-doubts, but remember, good things take time.",
    createdAt: 'June 09, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*-iwRLRes2lVlUT2Jvi8nKA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-mastercard-khushi-agrawal-4c9d8366bbde',
  },
  {
    id: 180,
    title: 'BCG | Sanil Upasani',
    category: 'Consultancy',
    company: "BCG",
    description:
      "To excel in case solving and develop strong business acumen, I suggest starting with Victor Cheng’s YouTube playlist to grasp the case fundamentals. Then, dive into solving cases from reputable sources like the IIM -A and IIM -B casebooks",
    createdAt: 'June 07, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*3CbjHbCqVRDWZkjYxuQfeg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/1-brief-introduction-and-description-of-offered-role-fa2a3cc917a8',
  },
  {
    id: 179,
    title: 'Nvidia | Sneharghya Debnath',
    category: 'Core',
    company: "Nvidia",
    description:
      "I studied the basics of Verilog from Prof. Indraneel Sengupta’s NPTEL Course. Similarly, I went through a bit of Computer Architecture (the first 10–12 lectures) from the NPTEL course on the same.",
    createdAt: 'June 07, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:640/format:webp/1*PC2dKWVU5jfOUBZaa7QSRg.png',
    medium: 'https://medium.com/@swgiitkgp/1-brief-introduction-and-description-of-the-offered-role-4238234451ac',
  },
  {
    id: 178,
    title: 'BlackRock | Lakshya Jindal Gupta',
    category: 'Finance',
    company: "BlackRock",
    description:
      "Holding a CFA or FRM certification can be advantageous. Additionally, candidates should have a strong foundation in basic bond valuations, macroeconomics, and an understanding of derivatives.",
    createdAt: 'June 07, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*G-W0bn3tO9iqIUhJDrd_zA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/1-brief-introduction-and-description-of-offered-role-44eace02e764',
  },
  {
    id: 177,
    title: 'L.E.K Consulting | Abhyank Singh',
    category: 'Consultancy',
    company: "L.E.K Consulting",
    description:
      "When it comes to preparing for consulting interviews, one of the most important steps is to begin by crafting a strong CV. Ensure it showcases a balanced profile, emphasizing your academic accomplishments, previous internships, positions of responsibility (PORs), and involvement in extracurricular activities.",
    createdAt: 'June 06, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*3tbKsacpMsiWYfaOuQFUJw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/1-brief-introduction-and-description-of-the-offered-role-6cfa8eba78ec',
  },
  {
    id: 176,
    title: 'Sprinklr | Arjit Lohia',
    category: 'Software',
    company: "Sprinklr",
    description:
      "You should prepare DSA thoroughly as it is required in both coding rounds and interviews. Give as many contests on Codeforces and codechef as possible as it will help in solving questions faster in coding rounds.",
    createdAt: 'June 06, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*0dUvf0PaN7aj7X9xWtZTQw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/1-brief-introduction-and-description-of-the-offered-role-71cd4bbace6f',
  },
  {
    id: 175,
    title: 'Sprinklr | Abhirup Pal',
    category: 'Software',
    company: "Sprinklr",
    description:
      "For practicing DSA at a beginner level, Leetcode is great. Once you are comfortable with basic topics you should participate in Codeforces or Codechef contests",
    createdAt: 'June 06, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*okOQzhSOUqejFWI7hGBwAQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/brief-introduction-and-description-of-the-offered-role-e60728fc6648',
  },
  {
    id: 174,
    title: 'AlphaGrep | Nabhya Khoria',
    category: 'Quant',
    company: "AlphaGrep",
    description:
      "The test scores really matter and not the CGPA, according to me. You can practice on Leetcode, CSES, Codeforces and Atcoder to practice such types of questions.",
    createdAt: 'June 05, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*Y_IenIJbspA-axYlWMJ_5g.jpeg',
    medium: 'https://medium.com/@swgiitkgp/1-brief-introduction-and-description-of-offered-role-87e2c5c516a7',
  },
  {
    id: 173,
    title: 'JLR | Sayantan Sarkar',
    category: 'Core',
    company: "JLR",
    description:
      "The most important chapters asked here are Mechanics of Solids, Fluid Mechanics, Design of Machine Elements, Kinematics and Kinetics of Machines, Basic Thermodynamics(including all the cycles there in the syllabus), and IC Engines.",
    createdAt: 'June 05, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:472/format:webp/1*5KHKjovPtjPgxpLOimmFgQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/1-brief-introduction-and-description-of-the-offered-role-3fb05e79b408',
  },
  {
    id: 172,
    title: 'Goldman Sachs | Vipin',
    category: 'Quant',
    company: "Goldman Sachs",
    description:
      "I focused on data structures and algorithms, probability concepts, and aptitude questions. Engaging with competitive programming on platforms like Codeforces, and Leetcode helped me a lot to prepare in a time-constrained setup.",
    createdAt: 'June 04, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*epKtntnU78sRs22DqKXm2w.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-goldman-sachs-vipin-1a0bbf79e54e',
  },
  {
    id: 171,
    title: 'Databricks | Bhuvan Rangoju',
    category: 'Software',
    company: "Databricks",
    description:
      "I recommend revising the PDS course and practicing implementation-based questions on Hackerrank and CF Div 2 (A, B) & Div 3 (A, B, C, D) questions to get a grip on writing code.",
    createdAt: 'June 03, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*55t3iTsuPT4LIPzp6LjgbQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-databricks-bhuvan-rangoju-c202f3f5ccd9',
  },
  {
    id: 170,
    title: 'Bajaj Auto | Ashutosh Dash',
    category: 'Core',
    company: "Bajaj Auto",
    description:
      "For the technical test, it’s crucial to have a solid grasp of core mechanical subjects like Solid Mechanics, Thermodynamics, Heat Transfer etc. and should also have a decent understanding of some first year mathematics",
    createdAt: 'June 01, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*a_aYF0V4oUdpUqKUOxHp3g.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-bajaj-auto-ashutosh-dash-b59cab20c6ac',
  },
  {
    id: 169,
    title: 'JM Financial Ltd. | Malla Dhanraj',
    category: 'Data',
    company: "JM Financial Ltd.",
    description:
      "Do some projects related to Machine Learning and Deep Learning. Know why you are using some specific algorithms in your projects. Keep your basics strong in Probability, Statistics, Machine Learning and Deep Learning.",
    createdAt: 'May 30, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*oE5Wvt0kBJgTGEwLtaVCLQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-jm-financial-ltd-malla-dhanraj-d416c71faeb3',
  },
  {
    id: 168,
    title: 'Goldman Sachs | Sanskar Gupta',
    category: 'Quant',
    company: "Goldman Sachs",
    description:
      "For coding preparation, understanding DSA concepts and practicing standard questions is essential. I started with videos and resources of Striver and Luv DSA.",
    createdAt: 'May 28, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*FmZAjsDz2QPvtWUMojrWyg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-goldman-sachs-sanskar-gupta-ab2daeee8bcb',
  },
  {
    id: 167,
    title: 'AlphaGrep | Pranav Kulkarni',
    category: 'Software',
    company: "AlphaGrep",
    description:
      "Apart from regular contests on Codeforces, CodeChef & Atcoder, participate in competitions like ICPC, HackerCup, etc. Mentioning good ranks in these competitions significantly boosts one’s CV.",
    createdAt: 'May 26, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*ljXbexXJuIsEh2ddJCrJgw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/1-brief-introduction-and-description-of-offered-role-42f0ad1fbc5e',
  },
  {
    id: 166,
    title: 'Amazon | Mohd Amaan',
    category: 'Software',
    company: "Amazon",
    description:
      "Start as early as you can, and don’t panic. If you are someone starting summer vacations, I suggest you practice in chunks and divide your time between theory and problem-solving.",
    createdAt: 'May 26, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*BxFtVwyAi2VEAba6dWs4cA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/1-a-brief-introduction-and-description-of-the-offered-role-b6e911a2cf07',
  },
  {
    id: 165,
    title: 'Nomura | Tejas Saxena',
    category: 'Finance',
    company: "Nomura",
    description:
      "You should know the contents of your CV inside out. Imagine being woken up at 4 a.m. and having to defend a particular internship or project on your CV without hesitation. Be ready to answer 5–6 follow-up questions about each",
    createdAt: 'May 26, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*_8cdW33MZNbnKFymns3WiQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/1-brief-introduction-and-description-of-the-offered-role-ebd933a9d5ed',
  },
  {
    id: 164,
    title: 'Texas Instruments | Avi Saraf',
    category: 'Core',
    company: "Texas Instruments",
    description:
      "It is essential to focus on getting your fundamentals right. The companies do not expect the students to know about advanced jargon but certainly gauge your interest in the domain and clarity in fundamental concepts.",
    createdAt: 'May 24, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*MismnkIJfcJD6Tu8C6KADw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-texas-instruments-avi-saraf-ef3418695f60',
  },
  {
    id: 163,
    title: 'Microsoft | Ayushi',
    category: 'Software',
    company: "Microsoft",
    description:
      "Practice problems from Leetcode, Codeforces, GeeksforGeeks, etc or whatever platform you are comfortable with and can keep InterviewBit for the last 1–2 months as it consists of just the right set of questions one requires before the assessments.",
    createdAt: 'May 21, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*52CHnWMAD_rVx4kmtqnLAg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-microsoft-ayushi-7f085a3250ba',
  },
  {
    id: 162,
    title: 'Adobe | N Anushka',
    category: 'Research',
    company: "Adobe",
    description:
      "Having good technical projects is always desirable, as it boosts your chances. A good project related to NLP or Computer Vision would enhance your resume considerably for this profile, as most of the projects in Adobe Research are related to these fields.",
    createdAt: 'May 19, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*CDEe27IEvMGFBINb6-6sBg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-adobe-n-anushka-student-welfare-group-iit-kharagpur-01f7540403f9',
  },
  {
    id: 161,
    title: 'BCG | Aorkodeep Mandal',
    category: 'Consultancy',
    company: "BCG",
    description:
      "Make the CV impact-driven, start with impact words, and use numbers to justify this whenever necessary. A “STAR” approach often works well. Since all you have is a 1 pager CV to cover as many spikes as possible, it is optimal to focus on cumulatively maximizing the number of spikes rather than over-stretching on a single sector.",
    createdAt: 'May 17, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*BEaVD6WauqN3n35f93-qmw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-bcg-aorkodeep-mandal-6ae188150395',
  },
  {
    id: 160,
    title: 'P&G | Kalidindi Rohan',
    category: 'FMCG',
    company: "P&G",
    description:
      "Revising fundamental concepts of your core engineering discipline for a couple of weeks before the process starts can prove advantageous. Familiarity with supply chain management basics can be beneficial.",
    createdAt: 'May 15, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:750/format:webp/1*nGSYgv0GhAOcQx5NoT_dKQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-procter-and-gamble-kalindindi-rohan-d7cb5ef5e426',
  },
  {
    id: 159,
    title: 'Goldman Sachs | Birru Lavanya',
    category: 'Quant',
    company: "Goldman Sachs",
    description:
      "For probability and puzzles, I followed the standard interview books. Heard on the street, 50 challenging problems in probablity, brainstellar etc",
    createdAt: 'May 12, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*suPwke5-wo06bLDlnN30rw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-goldman-sachs-birru-lavanya-d33db054904b',
  },
  {
    id: 158,
    title: 'Graviton Research Capital LLP | Swapnil Yasasvi',
    category: 'Software',
    company: "Graviton Research Capital LLP",
    description:
      "Focus on brushing up on your basics first. Spend a week or two on standard data structures/concepts like stacks, queues etc and number theory topics like the sieve method, as well as DFS and BFS.",
    createdAt: 'May 9, 2024',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*Oze4KLU3aQTF4zw38yJaTQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2024-internship-at-graviton-research-capital-llp-swapnil-yasasvi-b6f3a43b448a',
  },
  {
    id: 157,
    title: 'DSP | Aditi Maurya',
    category: 'Quant',
    company: "DSP",
    description:
      "Practice guesstimates, probability and statistics, and quizzes for a safer side. In most of the quant profiles, guesstimates and statistics are asked.",
    createdAt: 'August 19, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*pPbeQV1Np6sIh9jc2vqk1A.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-dsp-debabrata-bhakat-d35198ed9f98',
  },
  {
    id: 156,
    title: 'DSP | Debabrata Bhakat',
    category: 'Quant',
    company: "DSP",
    description:
      "Be ready with all the basic ML algorithms in case they ask to implement it from scratch. For this, you can follow any online resource like Andrew Ng on Coursera or Krish Naik’s tutorials.",
    createdAt: 'August 19, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*8bvQADeGHp_YAt3khqTaRA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-dsp-debabrata-bhakat-879ff0b537e6',
  },
  {
    id: 155,
    title: 'Amazon | Aryan Gupta',
    category: 'Software',
    company: "Amazon",
    description:
      "It is very important to practice from Leetcode and InterviewBit for the standard questions. I  prepared the usual set of topics, used GFG and YT as my helping hand, and gave contests to prepare myself for the time-constrained setup.",
    createdAt: 'August 12, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:640/format:webp/1*8zM8gRCyneqMZwjrxK3dgQ.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-amazon-aryan-gupta-292cae10b8d',
  },
  {
    id: 154,
    title: 'Honeywell | Tanmay Vaghale',
    category: 'Software',
    company: "Honeywell",
    description:
      "Keep practising DSA questions regularly, especially DP and Graphs. Even if you solve fewer problems on certain days, it is fine if those problems are challenging.",
    createdAt: 'August 11, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*2yvXrHlwWRzKMm4wZjPNHg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-honeywell-tanmay-vaghale-32bdb5b16ef8',
  },
  {
    id: 153,
    title: 'Honeywell | Parth Nanda',
    category: 'Software',
    company: "Honeywell",
    description:
      "Focus on DSA. Try to do problems on websites like GFG, interview bit, leetcode",
    createdAt: 'August 11, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*65284aEtHj2hFLQj',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-honeywell-parth-nanda-37694260cb33',
  },
  {
    id: 152,
    title: 'AWL Inc. | Manav Nitin Kapadnis',
    category: 'Data',
    company: "AWL Inc.",
    description:
      "Brush up on common interview questions and practice your responses. Be prepared to discuss your experiences, projects, and technical knowledge.",
    createdAt: 'August 11, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:786/format:webp/1*MHk2vpgE7MeEC4-Bmh4QLQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-awl-inc-manav-nitin-kapadnis-b0da07771812',
  },
  {
    id: 151,
    title: 'Qualcomm | Shrey Thakkar',
    category: 'Data',
    company: "Qualcomm",
    description:
      "Prob-stats usually help in any Data oriented profile and it is a must if you’re targeting it. Solve standard puzzles and probability questions from books like 50 Challenging Problems and Heard on the Street etc.",
    createdAt: 'August 11, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*gTHRDayv40m7XXSCRz8cMQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-qualcomm-shrey-thakkar-98dab16630a5',
  },
  {
    id: 150,
    title: 'Qube Research & Technologies | Ashutosh Ghuge',
    category: 'Quant',
    company: "Qube Research & Technologies | Ashutosh Ghuge",
    description:
      "For the Quantitative Research profile, a good grip on Machine Learning concepts would help, 50 Challenging Problems in Probability, BrainStellar(website), Heard on the Street are few resources to exploit",
    createdAt: 'August 11, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*l7UWiqBCe6wZgv_AaFROJg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-qube-research-technologies-ashutosh-ghuge-784b8a1ae22a',
  },
  {
    id: 149,
    title: 'Cisco | Gaurav Madkaikar',
    category: 'Software',
    company: "Cisco",
    description:
      "Keep reading about the various algorithms through articles on geeksforgeeks and cp-algorithms.com (specially for graphs). Practicing them regularly via leetcode/interviewbit would help",
    createdAt: 'August 7, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:640/format:webp/1*e0Yxxg4zQ75Vfg8T21iBzA.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-cisco-gaurav-madkaikar-5890ad1391f8',
  },
  {
    id: 148,
    title: 'Honeywell | Nitin Rohit',
    category: 'Software',
    company: "Honeywell",
    description:
      "All companies which are recruiting for a software profile need a bare minimum of basic knowledge about Data structure and algorithms. So, I started learning the DSA from courses and once I had my basics clear, I moved to solving problems and giving contests on various platforms like cf and cc.",
    createdAt: 'August 7, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*RrvptJNHgzU1Q_8ZF194sw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-honeywell-nitin-rohit-45f5df3adfb5',
  },
  {
    id: 147,
    title: 'Honeywell | Mihir Raj',
    category: 'Software',
    company: "Honeywell",
    description:
      "Concentrate more on Dynamic programming and graphs, as these topics are included in practically every company’s test.You could practice questions on sites such as Leetcode, InterviewBit, and GFG because the questions will be pretty similar.",
    createdAt: 'August 7, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:750/format:webp/1*ci_M6OOGgZpoFiIexsz1yA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-american-express-mihir-raj-2879d5683d2e',
  },
  {
    id: 146,
    title: 'American Express | Omkar Modi',
    category: 'Data',
    company: "American Express",
    description:
      "Good knowledge of conditional probability, distributions, and expectations was required for ML interviews and tests. The Brainsteller website offers a curated collection of questions and puzzles",
    createdAt: 'August 4, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*s72HJdKMsh5xs5jui2qgIg.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-american-express-omkar-modi-58e8c8ca56d8',
  },
  {
    id: 145,
    title: 'Bajaj | Sreyash Layek',
    category: 'Core',
    company: "Bajaj",
    description:
      "Knowledge of basic statistics, probability, Permutation and Combination is a must and you can practice additional questions from CAT's previous papers, solve puzzles from brainstellar.com",
    createdAt: 'August 4, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*Dc2auHUT5XeVdNPi',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-bajaj-sreyash-layek-f7037001e7af',
  },
  {
    id: 144,
    title: 'Bajaj | Venkata Sai Saran Grandhe',
    category: 'Core',
    company: "Bajaj",
    description:
      "One needs to be sure about the projects they have put in the CV from the point, even if the person has contributed, don’t mention that you have done a total project in CV.",
    createdAt: 'August 4, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*8-hnCgmC5ngABKiK_KjsQQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-bajaj-venkata-sai-saran-grandhe-1009586292b0',
  },
  {
    id: 143,
    title: 'Bajaj | Preeti Nilanjana',
    category: 'Core',
    company: "Bajaj",
    description:
      "For questions related to the electrical core, one should know the basics of electrical machines, analog and digital electronics, measurements, and instrumentation.",
    createdAt: 'August 4, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*qjMaDEmQKzAugEkTx8_iSw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-bajaj-preeti-nilanjana-ada8dbaa71ce',
  },
  {
    id: 142,
    title: 'Salesforce| Shreyansh Shikhar',
    category: 'Software',
    company: "Salesforce",
    description:
      "Do competitive programming and puzzles for software and quant roles. I would suggest not writing CP type code in interviews.",
    createdAt: 'August 3, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*rCARwmLNCD__Ejzn-5ufvw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-salesforce-shreyansh-shikhar-b98e14e3751e',
  },
  {
    id: 141,
    title: 'Nutanix | Nikhil Saraswat',
    category: 'Software',
    company: "Nutanix",
    description:
      "To prepare for DSA, one must practice problems based on various topics such as Graphs, Dynamic Programming, Binary Search, Two-Pointer, DSU, Trie, etc. It is also essential to develop soft skills.",
    createdAt: 'July 30, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*FynkVNUkbAPOz8OVtnDAzg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-nutanix-nikhil-saraswat-59224c81fdb8',
  },
  {
    id: 140,
    title: 'Goldman Sachs | Vidushi Agarwal',
    category: 'Software',
    company: "Goldman Sachs",
    description:
      "As you approach the last two months of your preparation, I strongly recommend using InterviewBit, as their problem set covers all the concepts you need to know and are likely to encounter in coding interviews",
    createdAt: 'July 30, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:786/format:webp/1*86C13N88xDq9C_tV3WsnaQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-goldman-sachs-vidushi-agarwal-b92cebf26da8',
  },
  {
    id: 139,
    title: 'Goldman Sachs | Vishal Kumar',
    category: 'Software',
    company: "Goldman Sachs",
    description:
      "For the interviews, concentrate on strengthening your DSA knowledge and reviewing the fundamentals of Object-Oriented Programming. Additionally, explore resources like BrainStellar and YouTube",
    createdAt: 'July 30, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*PCWojaihkhdsQnLm',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-goldman-sachs-vishal-kumar-c3fed68669fc',
  },
  {
    id: 138,
    title: 'Goldman Sachs | Harsh Sukheja',
    category: 'Quant',
    company: "Goldman Sachs",
    description:
      "STAT 110 is a super helpful course.Easy/ moderate questions on the interview bit is compulsory.In the coursework section of CV, highlight probability and data science courses",
    createdAt: 'July 30, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*tO4y9VJ6oS117HRIYxsL4g.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-goldman-sachs-harsh-sukheja-2b95bedb84eb',
  },
  {
    id: 137,
    title: 'Jaguar Land Rover | Sayan Layek',
    category: 'Software',
    company: "Jaguar Land Rover",
    description:
      "Basically you need to have a sound understanding of Algorithms and practice DSA. If you are into Competitive Programming, then that’s great. Also you should have some basic understanding of OOPs.",
    createdAt: 'July 29, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*EMSn-Ke5XSgcYITERajWnw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-jaguar-land-rover-rishi-suman-5e6a7ebca965',
  },
  {
    id: 136,
    title: 'Jaguar Land Rover | Rishi Suman',
    category: 'Software',
    company: "Jaguar Land Rover",
    description:
      "Practice a lot. Try to solve as many problems as possible. Give contests on Codechef/Codeforces. During the last few months, try to complete Interviewbit and solve problems from Leetcode/GFG",
    createdAt: 'July 29, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*4AnGM_jhlbxPpB1lEgpsBA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-jaguar-land-rover-rishi-suman-f465b7bad2c',
  },
  {
    id: 135,
    title: 'JP Morgan Chase & Co. | Akansha Agarwal',
    category: 'Data',
    company: "JP Morgan Chase & Co.",
    description:
      "For aptitude and quant section,brush up on Probability and Statistics courses, CAT questions, JEE level math. Basically, solving fast and accurately in a time-constraint is required.",
    createdAt: 'July 27, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*HV1KHPwbInoD9RhxQAnNHA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-jp-morgan-chase-co-akansha-agarwal-2877afb0203c',
  },
  {
    id: 134,
    title: 'Jaguar Land Rover| Polu Sreenidhi',
    category: 'Core',
    company: "Jaguar Land Rover",
    description:
      "Good and clear understanding of basic fundamentals and theory is needed. And it would be better to take some useful courses like VLSI, Mixed IC Design etc (in platforms like Coursera) or do any self-project in this summer related to their domain.",
    createdAt: 'July 27, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*varOihQ2TxaT96aHrPWKwg.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-jaguar-land-rover-polu-sreenidhi-92d5c0562640',
  },
  {
    id: 133,
    title: 'Jaguar Land Rover| Avinash Kumar',
    category: 'Core',
    company: "Jaguar Land Rover",
    description:
      "Start with revising the power related courses (if you are Electrical), digital and electronics circuit. Questions related to minimization of gate, multiplexer is frequently being asked.",
    createdAt: 'July 27, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:640/format:webp/1*GL9I64njUqNJyhvj4HFpjA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-jaguar-land-rover-avinash-kumar-7744f07bed98',
  },
  {
    id: 132,
    title: 'NVIDIA | Madhav Raju Nair',
    category: 'Core',
    company: "NVIDIA",
    description:
      "The most important step is revising all the topics covered in 2nd-year digital electronics course; questions can be asked from basic circuits, registers, flip flops, multiplexers, PLL etc.",
    createdAt: 'July 26, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*M7Z0mfOKInRXkfC9swixMQ.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-nvidia-madhav-raju-nair-31d808d70557',
  },
  {
    id: 131,
    title: 'Bain and Company | Ragini Laskar',
    category: 'Consultancy',
    company: "Bain and Company",
    description:
      "The major part of preparation is practising cases with your batchies or seniors, who will act as the interviewer and guide you through the case. You can practise guesstimates on your own as well.",
    createdAt: 'July 25, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*lGXuikqedejXAoMW',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-bain-and-company-ragini-laskar-a588d4a8abf8',
  },
  {
    id: 130,
    title: 'AlphaGrep Securities | Rishit Singhania',
    category: 'Quant',
    company: "AlphaGrep Securities",
    description:
      "If you are targeting Quant role, you can prepare puzzles from 50 Challenging Problems in Probability by Frederick Mosteller, Heard on the Street by Timothy Falcon I studied Object Oriented Programming from the course slides and GeekforGeeks.",
    createdAt: 'July 25, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*MvcSOGqvOxbDjR4aTb-HSA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-alphagrep-securities-rishit-singhania-b38debdc6702',
  },
  {
    id: 129,
    title: 'Graviton | Shivansh Shukla',
    category: 'Quant',
    company: "Graviton",
    description:
      "Apart from regular contests on Codeforces, CodeChef & Atcoder, do participate in competitions like ICPC, CodeJam, Kickstart, HashCode, HackerCup, SnackDown, etc. Mentioning good ranks in these contests give a huge boost to one’s CV.",
    createdAt: 'July 23, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*7hhRsB4IC_xTjxnIyAoJqA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-graviton-shivansh-shukla-78ca91a9b91f',
  },
  {
    id: 128,
    title: ' ITC | Vardhan',
    category: 'FMCG',
    company: " ITC",
    description:
      "They check how we can implement the projects we have done in their company. However, if one wants to prepare for any FMCG role, one needs to be thorough with your CV, have a good grasp of basic physics and chemistry, look at guesstimated, and have a diverse profile. Apart from this, you need to have good decision-making skills and on spot-thinking, as the HR round was very tricky and logical.",
    createdAt: 'July 23, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*0FOZFAOnsfVZjkmFc1ZUpw.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-itc-vardhan-81dba66963b5',
  },
  {
    id: 127,
    title: 'Morgan Stanley | Mayank Agarwal',
    category: 'Quant',
    company: "Morgan Stanley",
    description:
      "You should solve the ‘top 100 puzzles asked in interviews’ from GFG as they are asked directly(in other companies’ interviews as well). Interviewbit should be enough for the coding questions. ",
    createdAt: 'July 22, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*RlZP55JabUTC_BczX-iMaw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-morgan-stanley-mayank-agarwal-646e6726a7d',
  },
  {
    id: 126,
    title: 'Morgan Stanley | Jaya Kediya',
    category: 'Quant',
    company: "Morgan Stanley",
    description:
      "For Puzzles, make sure to solve questions from the book Heard on the Street, all puzzles from Mindsteller and GeeksforGeeks. A lot of questions that were asked in interviews were direct or derived from Hard rated puzzles from GeeksforGeeks itself.",
    createdAt: 'July 22, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:786/format:webp/1*BE9kEuoSOXZQUni2xhik_Q.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-morgan-stanley-jaya-kediya-8fc6526d8938',
  },
  {
    id: 125,
    title: 'Sprinklr | Agnibha Sinha',
    category: 'Software',
    company: "Sprinklr",
    description:
      "Leetcode & Interviewbit are good for getting a grasp of the standard questions. Participating in contests on Codeforces and Codechef regularly helps in improving your speed and ability to solve new problems on the spot.",
    createdAt: 'July 21, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*2RPtQVCWc3EW0dDhnyzz0A.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-sprinlr-agnibha-sinha-6f5495fe0ffc',
  },
  {
    id: 124,
    title: 'Sprinklr | Adarsh',
    category: 'Software',
    company: "Sprinklr",
    description:
      "Overall, practicing on Codeforces significantly helped me since the platform exposes you to a wide range of problems, including those that may appear unexpectedly in tests or interviews.",
    createdAt: 'July 21, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*4ZPbrIeCEkLHiChK',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-sprinklr-adarsh-9ab9807680e2',
  },
  {
    id: 123,
    title: 'Sprinklr | Aalok Agrawal',
    category: 'Software',
    company: "Sprinklr",
    description:
      "Many of the questions on the coding tests I encountered were similar to those found on LeetCode. I personally solved around 400 questions from the well-known LeetCode sheet and also used InterviewBit in the final months of my preparation to reinforce my understanding of key concepts.",
    createdAt: 'July 21, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*67YBggNseWiOD6MghWbJNA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-sprinklr-aalok-agrawal-c0710ba037fb',
  },
  {
    id: 122,
    title: 'Oracle | Gautam Singla',
    category: 'Software',
    company: "Oracle",
    description:
      "Solving a lot of problems will improve your implementation skills which are important because if you write buggy codes during the coding rounds, you will not have enough time to debug your code even if your logic is correct.",
    createdAt: 'July 20, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*nX6DEg4p2b75gV0e-7sdLw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-oracle-gautam-singla-94a8aba298c6',
  },
  {
    id: 121,
    title: 'Oracle| Riddhiman Moulick',
    category: 'Software',
    company: "Oracle",
    description:
      "Having previous projects or internships gives you control over the topic during the CV discussion with the interviewer so be sure to highlight such points in your CV. Be thorough with all the points in your CV and give it a look before the interview as you can be asked to describe specific parts of your projects too.",
    createdAt: 'July 20, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*4lPA4Hr5V44b-6iXkMm6mg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-oracle-riddhiman-moulick-42197a23a71',
  },
  {
    id: 120,
    title: 'Quadeye | Rajas Bhatt',
    category: 'Systems',
    company: "Quadeye",
    description:
      "Practice CP questions to hone your algorithmic skills since they ask such questions in every technical interview. Moreover, it is important to have in-depth knowledge about CS concepts. OOPS, Networks and OS are very important for Dual Degree students.",
    createdAt: 'July 20, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*bc_IGE3qqRCnsD_JdOWOIw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-quadeye-rajas-bhatt-7ccf02bda734',
  },
  {
    id: 119,
    title: 'Quadeye | Vishal Subramanyam',
    category: 'Systems',
    company: "Quadeye",
    description:
      "I used textbooks like C++ Primer and Effective Modern C++ for this. I also used to spend time reading the source code of different open-source projects to get a feel for real-world code since this is not something that you usually get from coursework.",
    createdAt: 'July 20, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*NdprsxGbtit-QBQp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-quadeye-vishal-subramanyam-aaa6a9b1fe27',
  },
  {
    id: 118,
    title: 'Quadeye | Suryam Arnav Kalra',
    category: 'Systems',
    company: "Quadeye",
    description:
      " The interviews cover a vast variety of topics ranging from Computer Networks, Object Oriented Programming, Operating Systems, Computer Architecture and Organization, Compilers, Competitive Programming and last but not the least mathematical puzzles.",
    createdAt: 'July 20, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:786/format:webp/0*YB3Q57bC2STvGwCt',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-quadeye-suryam-arnav-kalra-bceca59f995a',
  },
  {
    id: 117,
    title: 'Adobe | Raghav Aggarwal',
    category: 'Software',
    company: "Adobe",
    description:
      "One thing students get often confused between is Competitive Programming and DSA. They’re not the same and hence if you’re practicing on just Codeforces or Codechef start doing DSA practice as well. CP is good for timed practice and increasing analytical thinking but most of the companies in SDE are going to ask questions based on fundamental applications of DSA.",
    createdAt: 'July 20, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*XLMXAeJcrxzqHCUM',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-adobe-raghav-aggarwal-5f4c02f664a7',
  },
  {
    id: 116,
    title: 'Nomura| Anshul Jadhav',
    category: 'Finance',
    company: "Nomura",
    description:
      "Try getting research projects in this domain at VGSOM, ISB, IIMs, or other foreign universities. Internships in this domain will give you an edge in the CV screening round and a good amount of details to discuss during the interview.",
    createdAt: 'July 19, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*kVyRZwBXYodkcebFnjLKwA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-nomura-anshul-jadhav-d77c991630bc',
  },
  {
    id: 115,
    title: 'Salesforce | Srijanak De',
    category: 'Software',
    company: "Salesforce",
    description:
      "I think adding any projects or internships adds real value to the resume, no matter how small, even course projects work. Adding relevant coursework, languages, or toolkits most comfortable with is a must. ",
    createdAt: 'July 18, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*SW-JAZ0et5M1UDyL',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-salesforce-srijanak-de-a00ad981414',
  },
  {
    id: 114,
    title: 'Salesforce| Atulya Sharma',
    category: 'Software',
    company: "Salesforce",
    description:
      "Do interviewbit / leetcode properly to get practice. Also keep in mind that only doing CP won’t help you, since you need to have proper practice in all topics. If you don’t have any projects, it’s fine. Your first priority should be to get good enough to first clear those coding rounds.",
    createdAt: 'July 18, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:750/format:webp/1*Mg4U5FpexT0cMVdPx9Uccw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-salesforce-atulya-sharma-1e862dd2ae02',
  },
  {
    id: 113,
    title: 'BCG | Dipanki Mukherjee Pabreja',
    category: 'Consultancy',
    company: "BCG",
    description:
      "I would suggest thoroughly going through the books “Case Interview Cracked” and “Day 1.0: The Finance and Consulting Interview Guide”. All the available cases must be practiced with another person rather than going through them alone.",
    createdAt: 'July 17, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*BH2TIalI7aYPiitm',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-bcg-dipanki-mukherjee-pabreja-cd264cb15754',
  },
  {
    id: 112,
    title: 'LEK | Diya Sarkar',
    category: 'Consultancy',
    company: "LEK",
    description:
      "The unique thing about solving cases is that it’s important to solve cases with a partner — in this way, one of you can take the role of the interviewer and the other of the interviewee and you can get a better learning experience.",
    createdAt: 'July 14, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*ODGC5eVwh09rD_jBruOs5g.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-lek-diya-sarkar-61b55b15343',
  },
  {
    id: 111,
    title: 'Adobe | Shaurabh Tiwari',
    category: 'Data',
    company: "Adobe",
    description:
      "It will be preferred to have at least one good project/internship on your CV for a better understanding of the domain and also it’ll help as a starter for discussion during the interview. If you have time I’ll suggest looking into NLP or Computer Vision domain.",
    createdAt: 'July 14, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*NfxAPPVspDNkid84T-rb8Q.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-adobe-shaurabh-tiwari-aacd25f9fd36',
  },
  {
    id: 110,
    title: 'Adobe | Divyansh Raghuwanshi',
    category: 'Data',
    company: "Adobe",
    description:
      "I would suggest to start practising DSA questions as early as possible, for practise you can use LeetCode or GeeeksforGeeks, and you should try to participate in contests regularly and upsolve, as this would really help you to solve questions in a timed environment, in the last one month to get the idea about interview questions you can practise questions on InterviewBit.",
    createdAt: 'July 14, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*Xo0lXZ6LHyOjTi6i2Y3zeg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-adobe-divyansh-raghuwanshi-cba4759565ec',
  },
  {
    id: 109,
    title: 'NK Securities | Shrinivas Khiste',
    category: 'Quant',
    company: "NK Securities",
    description:
      "Codeforces contests are helpful to increase speed and debugging skills but the type of questions asked in coding rounds and CF contests differ a lot. C++ concepts can be revised by referring to the OOPs, OS slides from the course taught in KGP",
    createdAt: 'July 13, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*N2nFpYmDaHNr5rNqwESLTg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-nk-securities-shrinivas-khiste-cc637d0d4dce',
  },
  {
    id: 108,
    title: 'Texas Instruments | Ajay Yogendra',
    category: 'Core',
    company: "Texas Instruments",
    description:
      "I would highly recommend taking the Digital electronics course by Prof. Gautam Saha or going through his NPTEL lectures. It covers almost everything needed for the Digital profile",
    createdAt: 'July 13, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*s3XUzqIEvqiTeFMv',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-texas-ajay-yogendra-dfe7b5a7f638',
  },
  {
    id: 107,
    title: 'Texas Instruments | Tarang Sur Chaudhury',
    category: 'Core',
    company: "Texas Instruments",
    description:
      "Digital Electronic Circuits (4th sem EC course) is the definitive preparatory course one needs for the Digital Design profile — would also recommend Digital Design by M. Morris Mano. The questions were mostly based on logic gates, K-Maps, simple circuits like adders and ALUs. There were some basic questions from Computer Architecture related to cache and pipelining as well.",
    createdAt: 'July 13, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*8c3qzTy8k2Sozf4t9HMcaw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-texas-tarang-sur-chaudhury-643c03d08bda',
  },
  {
    id: 106,
    title: 'Reliance-Jio | Susnata Biswas',
    category: 'Software',
    company: "Reliance-Jio",
    description:
      " Interview Bit and Leetcode are possibly the best resources out there to prepare for coding rounds. Apart from coding it is essential to be familiar with your CV. You should be well-versed with what you have written in your CV.",
    createdAt: 'July 12, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*KMNS1j1hgvdDnDafSkl7oQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-reliance-jio-susnata-biswas-69fa7a66c019',
  },

  {
    id: 105,
    title: 'HUL | Sravani Gopi',
    category: 'FMCG',
    company: "HUL",
    description:
      "Preparation included practising cases with seniors and batchmates and revising basic concepts of supply chain which were covered in the course Logistics and Supply Chain Management of Industrial department. Else one can also take a good look at the book : Supply chain Management by Chopra and Meindl.",
    createdAt: 'July 11, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*0yEhJRaCLwVOjz8ZZoV0Lw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-hul-hari-g-db74a20049a5',
  },
  {
    id: 104,
    title: 'HUL | Hari G',
    category: 'FMCG',
    company: "HUL",
    description:
      "The key is to convince the interviewer that you are capable enough of molding yourself to this type of role given how you have done the same in past (with your real-life experience).",
    createdAt: 'July 11, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/1*8YQENH6zPLOjQ-8Ri7fMyw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-hul-hari-g-8970bfaebe5c',
  },
  {
    id: 103,
    title: 'DB | Rhea Vigil Alapatt',
    category: 'Finance',
    company: "DB",
    description:
      "DB looks for just a slight inclination towards finance. Basic knowledge of financial matters, even if it’s not deep, is all they need. They’re not expecting much out of the college students, they expect them to learn first.",
    createdAt: 'July 11, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*irJ5OHGP75aTB0uc',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-db-rhea-vigil-alapatt-9da71d3d9add',
  },
  {
    id: 102,
    title: 'Amex | Sagar Kumar Karn',
    category: 'Data',
    company: "Amex",
    description:
      "To prepare for Probability and Statistics, “50 Challenging Problems in Probability” and “Heard on the Street” are great books. You can practice for the aptitude section from Brainstellar.",
    createdAt: 'July 10, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*enHZaF16Gqa5picf',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-amex-sagar-kumar-karn-ee0c42a1795e',
  },
  {
    id: 101,
    title: 'Amex | Sneha Kharya',
    category: 'Data',
    company: "Amex",
    description:
      "For ML part after learning the concepts you can go through various online resources like InterviewBit to practice ML questions.",
    createdAt: 'July 10, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*MekYjm_xCVAQ8Uz20iKEXQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-amex-sneha-kharya-aee4165a7a16',
  },
  
  {
    id: 100,
    title: 'Glean | Aryan Singh',
    category: 'Software',
    company: "Glean",
    description:
      "As a growing company that has built its first iteration of the product, Glean offers many new challenges, such as scalability, efficiency, and harnessing large language models (LLMs) to solve enterprise-level problems.",
    createdAt: 'July 8, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*UyEwyEowyuzhx94wGmHnfg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-glean-aryan-singh-8981392255db',
  },
  {
    id: 99,
    title: 'Piramal | Sai Kalyan Meghavath',
    category: 'Data',
    company: "Piramal",
    description:
      "For the Logical Reasoning & Quantitative Aptitude part, I suggest solving CAT questions and using the website Brainstellar for puzzles(Easy & Moderate puzzles, Strategy & Probability puzzles).",
    createdAt: 'July 6, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*dPwqxMWPdhCi98rFg3D5uQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-piramal-sai-kalyan-meghavath-a6358327817f',
  },
  {
    id: 98,
    title: 'Cisco | Dhruv Rathi',
    category: 'Software',
    company: "Cisco",
    description:
      "For the technical interviews, you can practice saying your logic along with coding the problem.",
    createdAt: 'July 4, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*oFuft41Csfk_W4iHZOV3UA.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-cisco-dhruv-rathi-4da633eef03',
  },
  {
    id: 97,
    title: 'AlphaGrep | Sidharth Vishwakarma',
    category: 'Software',
    company: "AlphaGrep",
    description:
      "The coding round had 5 CP questions to be solved in 90 mins. They were from a varying range of difficulties.",
    createdAt: 'July 3, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*dkUo552lKkMEB3UK9MJeNw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-alphagrep-sidharth-vishwakarma-ebc60c81551',
  },
  {
    id: 96,
    title: 'ITC | Aditya Uppal ',
    category: 'FMCG',
    company: "ITC",
    description:
      "Linear thinking and simple case solving can be practiced to ensure a solid performance in the GD.",
    createdAt: 'July 1, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*Kkq--SoHj2dC9Osj0haxpg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-itc-aditya-uppal-8023721b34a8',
  },
  {
    id: 95,
    title: 'LEK | Ram Sundaram',
    category: 'Consultancy',
    company: "LEK",
    description:
      "Consulting shortlists are purely CV-based with no department biases (CG does matter, though, unfortunately).",
    createdAt: 'June 30, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*lqoXxaG37qClg7-iXScung.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-lek-ram-sundaram-409bbd5f3d68',
  },
  {
  id: 94,
  title: 'Sprinklr | Gaurav Malakar',
  category: 'Software',
  company: "Sprinklr",
  description:
    "During my preparation, I also solved problems from a website called InterviewBit, which I recommend starting around 1.5 months before coding rounds.",
  createdAt: 'June 28, 2023',
  cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*PziKcvw62OLVJO9dBZWYkQ.jpeg',
  medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-sprinklr-gaurav-malakar-1f7f7d3cab13',
},
  {
    id: 93,
    title: 'BCG | Aryansh Singh',
    category: 'Consultancy',
    company: "BCG",
    description:
      "Those who love a fast-paced line with challenging problem statements, that can change the commercial or public sector as we know it, should put in a fight for consulting.",
    createdAt: 'June 25, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*b3__nHd0fN2vbvevNH-BbQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-bcg-aryansh-singh-78a75f23da3d',
  },
  {
    id: 92,
    title: 'DE Shaw | Jothi Prakash',
    category: 'Software',
    company: "DE Shaw",
    description:
      "The questions were of moderate difficulty with the problems ranging between 1400 to 1700 rating on codeforces.",
    createdAt: 'June 23, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*6BY4zvvv2EskLfdOIGr1kA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-de-shaw-jothi-prakash-1b60947c962f',
  },
  {
    id: 91,
    title: 'Honeywell | Parv Jain',
    category: 'Software',
    company: "Honeywell",
    description:
      "The people who are interested in AI/ML can also go for it as there is a good chance that your project is based on these domains as well.",
    createdAt: 'June 21, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*vMrGWGcxQYCboNNxqw6Exw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-honeywell-parv-jain-3f622bef9934',
  },
  {
    id: 90,
    title: 'Trexquant | Srimahn',
    category: 'Quant',
    company: "Trexquant",
    description:
      "The test was designed to test two things: your understanding of financial markets and your approach to modelling a problem using Machine Learning.",
    createdAt: 'June 20, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*3lwmNUq89ud7K3B6dB12bg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-trexquant-srimahn-74814e13c7e',
  },
  {
    id: 89,
    title: 'JSW | Lalam Hari Prasad',
    category: 'Core',
    company: "JSW",
    description:
      "Solving core Metallurgical engineering and aptitude questions for the Assessment round would be good enough to get through it.",
    createdAt: 'June 18, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*ao5w63fJ12oQKG6YutYnFA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-jsw-lalam-hari-prasad-65658ab756a4',
  },
  {
    id: 88,
    title: 'Goldman Sachs | Himadri Pandya',
    category: 'Quant',
    company: "Goldman Sachs",
    description:
      " I had two rounds of technical interviews (some people had three rounds) on Zoom where I was asked logic-based puzzles and questions on probability and statistics.",
    createdAt: 'June 14, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*ZffoPDzKxG9IJ10g1mgtnQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-goldman-sachs-himadri-pandya-f49fe8aed7b3',
  },
  {
    id: 87,
    title: 'P&G | Pratik Koranne ',
    category: 'Product',
    company: "P&G",
    description:
      "The first step of the selection procedure comprises online gamified assessments which consist of various types of challenges such as grid challenge, digit challenge, switch challenge, etc.",
    createdAt: 'June 11, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*r1T4j0xcHflON5iNWKjQvw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-p-g-pratik-koranne-ee1b97d6364e',
  },
  {
    id: 86,
    title: 'Honeywell | Rithick Prabakar|',
    category: 'Software',
    company: "Honeywell",
    description:
      "The important topics are Dynamic Programming or DP, Graphs, Bit manipulation, Trees, and Heaps.",
    createdAt: 'June 9, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*8KEan1cpSclbhkyIBb3EIw.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-honeywell-rithick-prabakar-c1d26e036195',
  },
  {
    id: 85,
    title: 'APT | Ishan Manchanda',
    category: 'Quant',
    company: "APT Portfolio",
    description:
      "I would estimate the question complexity to span 1400–1800 Codeforces rating or so, the questions were definitely of a slightly higher level than the average CDC coding test that season.",
    createdAt: 'June 7, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*MYZWl3l-gRErvp4Al1iAVA.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-apt-ishan-manchanda-51324859632',
  },
  {
    id: 84,
    title: 'Google | Rashi Patil',
    category: 'Software',
    company: "Google",
    description:
      "Try to solve as many problems as you can while understanding the logic deeply to the roots of every problem. Give more contests on codeforces/CodeChef and get a hold of CP.",
    createdAt: 'June 4, 2023',
    cover: 'assets/images/rashi.jpg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-google-rashi-patil-56304f193230',
  },
  {
    id: 83,
    title: 'Morgan Stanley | Priyansha Gupta',
    category: 'Finance',
    company: "Morgan Stanley",
    description:
      "The test duration was around two and a half hours. The sections were Mental Ability, Mathematics or Probability and Statistics, English, Finance, Speaking, Listening and Coding.",
    createdAt: 'June 2, 2023',
    cover: 'assets/images/priyansha.jpg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-morgan-stanley-priyansha-gupta-61c647cc6e58',
  },
  {
    id: 82,
    title: 'Bain and Co. | Bharat Uday',
    category: 'Consultancy',
    company: "Bain and Co.",
    description:
      "In order to prepare for the interview process at Bain, there are two things that one must prepare for: Guesstimates and Business Cases.",
    createdAt: 'May 31, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*AYIY7vyb0hhDwwI64z0BfQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-bain-and-co-bharat-uday-f83dd14bd0bb',
  },
  {
    id: 81,
    title: 'AWL | Shubham Kulkarni',
    category: 'Data',
    company: "AWL",
    description:
      "You should have a few internships or projects or competitions on your CV showcasing that you have some previous experience in ML.",
    createdAt: 'May 29, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*ks3fasSZE4yH4wwQIFJ3lg.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-awl-shubham-kulkarni-8b110aa140fb',
  },
  {
    id: 80,
    title: 'Honeywell | Utkarshini Dubey',
    category: 'Software',
    company: "Honeywell",
    description:
      "As far as the test for Honeywell is concerned, it was conducted on HackerRank, and it consisted of 3 DSA questions to be solved in 1 hour.",
    createdAt: 'May 26, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*D8q9LOK6k2wraaR1IIW6-A.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-honeywell-utkarshini-dubey-f4c23eda1bc4',
  },
  {
    id: 79,
    title: 'Nvidia | Srikar Gidugu',
    category: 'Core',
    company: "Nvidia",
    description:
      "Start with revising the basics of digital electronics taught in 2nd year.",
    createdAt: 'May 23, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*j28E3fZa14qyY6xbRSFNcg.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-nvidia-srikar-gidugu-3287177436dc',
  },
  {
    id: 78,
    title: 'JPMC | Vaishnavi Singh',
    category: 'MRGR',
    company: "JPMC",
    description:
      "I will be interning at JPMC this summer as a Model Risk Governance and Review (MRGR) intern in the Global Risk and Compliance Program",
    createdAt: 'May 19, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*BuQBvqFvB8KcPilU0ZSQ2A.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-jpmc-vaishnavi-singh-b67a8ddd18a5',
  },
  {
    id: 77,
    title: 'Jaguar Land Rover | Daksh Varshney',
    category: 'Software',
    company: "Jaguar Land Rover",
    description:
      "Anyone interested in Software roles should apply for this job because your department won’t be a barrier.",
    createdAt: 'May 16, 2023',
    cover: 'https://miro.medium.com/v2/resize:fit:2000/format:webp/1*8GG4amXg9ZEnYqyELnDmOQ.jpeg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2023-summer-internship-at-jaguar-land-rover-daksh-varshney-b69419f6f274',
  },
  {
    id: 1,
    title: 'Dr. Reddy’s Laboratory | Compilation',
    category: 'Core',
    company: "Dr. Reddy's Laboratory",
    description:
      "Dr. Reddy’s Laboratories is a leading multinational pharmaceutical corporation with operations in India and overseas.",
    createdAt: 'August 10, 2022',
    cover: 'https://miro.medium.com/max/1100/1*-20ahy4QvQS8Og8xSDYUEA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-dr-reddys-laboratory-compilation-680a12adccc4',
  },
  {
    id: 2,
    title: 'AB InBev | Sumit Kumar',
    category: 'FMCG',
    company: 'AB InBev',
    description:
     "Students with good PORs and leadership experience targeting managerial roles in the future can apply for this profile.",
    createdAt: 'July 29, 2022',
    cover: '/assets/images/sumit.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-ab-inbev-sumit-kumar-33b84cfa7835',
  },
  {
    id: 3,
    title: 'Sprinklr | Compilation',
    category: 'Software',
    company: 'Sprinklr',
    description:
      "Sprinklr offers the role of Product Engineering interns on our campus. It is similar to SDE.",
    createdAt: 'July 25, 2022',
    cover: 'https://miro.medium.com/max/1100/1*pTBzsyenOkHG9Te_YCE_DA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-sprinklr-compilation-b0bc405febf3',
  },
  {
    id: 4,
    title: 'Intuit | Avijit Mandal',
    category: 'Software',
    company: 'Intuit',
    description: "If you like solving logical problems, doing coding in general, or are interested in Software Development, then you can be a good fit",
    createdAt: "July 21, 2022",
    cover: 'https://miro.medium.com/max/786/1*dnG_uvrdnLcTL2m_iryGeg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-intuit-avijit-mandal-7cd1634534f2',
  },
  {
    id: 5,
    title: 'Oracle |Compilation',
    category: 'Software',
    company: 'Oracle',
    description:"there is job stability in this field compared to HFTs (High-Frequency Trading), so if you prefer this, then go for it.",      
    createdAt: 'July 18, 2022',
    cover: 'https://miro.medium.com/max/1100/1*kCx3-ejh8jIjPR9wjnCesA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-oracle-compilation-2de2bfa7f4a1',
  },
  {
    id: 6,
    title: 'Google | Compilation',
    category: 'Software',
    company: 'Google',
    description:
      "A thorough understanding of algorithms and data structures is required for software engineering-based profiles",
  createdAt:'Jul 17,2022',
    cover:'https://miro.medium.com/max/1100/1*h_MDcYmhEppyMNn6utXJhQ.webp',
      medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-google-compilation-f6cf270289a9',
  },
  {
    id: 7,
    title: 'AlphaGrep | Amay Varma',
    category: 'Software',
    company: 'AlphaGrep',
    description:
      "AlphaGrep’s code base is primarily in C++ and hence they need employees who understand the language well.",
    createdAt: 'July 15, 2022',
    cover: '/assets/images/amay.jpg',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-alphagrep-amay-varma-d04e26fb7fa9',
  },
  {
    id: 8,
    title: 'Cisco | Abhranil Das',
    category: 'Software',
    company: 'Cisco',
    description:"Firstly, one has to be clear with all the concepts of Data Structures and Algorithms to get into a software developer role.",
    createdAt: 'July 14, 2022',
    cover: 'https://miro.medium.com/max/1100/1*-4PeN7ib-Sf8YUhUX0ic5w.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-cisco-abhranil-das-a77b3e75fd8e',
  },
  {
    id: 9,
    title: 'Microsoft | Compilation',
    category: 'Software',
    company: 'Microsoft',
    description:
      "Anyone who is interested in Software profiles and has a good hold of DSA can apply for this job",
    createdAt: 'July 15, 2022',
    cover: 'https://miro.medium.com/max/1100/1*orKPlHTqUHRsAG0R-n-VRg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-microsoft-compilation-c1eb5a78bff0',
  },
  {
    id: 10,
    title: 'Salesforce | Md Areeb Hussain',
    category: 'Software',
    company: 'Salesforce',
    description:
      "Anyone who is really interested in working as a software developer in top product-based company must apply for this job",
    createdAt: 'July 13, 2022',
    cover: '/assets/images/areeb.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-salesforce-md-areeb-hussain-af0275db1d3e',
  },
  {
    id: 11,
    title: 'Honeywell | Compilation',
    category: 'Software',
    company: 'Honeywell',
    description:
      "Those who are interested in building software,coding,handling hefty data , DevOps,Machine Learning, Databases or DSA can apply here",
    createdAt: 'July 11, 2022',
    cover: 'https://miro.medium.com/max/1100/1*tIb9mYwEsmNj6SFXKophbw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-honeywell-compilation-a8dceac15572',
  },
  {
    id: 12,
    title: 'Disney+ Hotstar | Dibyendu Biswas',
    category: 'Software',
    company: 'Disney+ Hotstar',
    description:
      "Guys looking for an oppurtunity in Software Development or an entry-level oppurtunity with good compensation can apply",
    createdAt: 'July 10, 2022',
    cover: 'https://miro.medium.com/max/828/1*dcD9zQbY4H_MJul3wUbyXQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-disney-hotstar-dibyendu-biswas-66294eff6f81',
  },
  {
    id: 13,
    title: 'ITC | Ayush Sahoo',
    category: 'FMCG',
    company: 'ITC',
    description:
      "The role is Techno-Mangerial based, so people who want management-based jobs and experience without leaving their technical background completely",
    createdAt: 'July 9, 2022',
    cover: 'https://miro.medium.com/max/1100/1*QCkUDjSbCWuEqlLQ6CdwTw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-itc-ayush-sahoo-338323d01ed8',
  },
  {
    id: 14,
    title: 'Qualcomm | Sai Chaitanya Gajula',
    category: 'Software',
    company: 'Qualcomm',
    description:
      "Qualcomm is a semiconductor-based company,the AI team working on EdgeAI among many other things. Anyone who is interested in Deep Learning can apply.",
    createdAt: 'July 8, 2022',
    cover: 'https://miro.medium.com/max/1100/1*ZEeTwL2qJWSpAnveW4yyvA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-itc-sai-chaitanya-gajula-460b439114b1',
  },
  {
    id: 15,
    title: 'Google Hardware | Debdatta Hait',
    category: 'Core',
    company: 'Google',
    description:
      "Selections were mainly based CV. I was mostly asked questions from basic digital electronics FSM(Finite state machine) related question",
    createdAt: 'July 8, 2022',
    cover: 'https://miro.medium.com/max/1100/1*d1CjDQHggIiFUx-8mzHULQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-google-hardware-debdatta-hait-51e9ceed012b',
  },
  {
    id: 16,
    title: 'Texas Instruments | Anish Mondal',
    category: 'Core',
    company: 'Texas Instruments',
    description:
      "These roles are generally open for people of circuit branches already familiar with pre-requisite knowledge of each of these profiles",
    createdAt: 'July 7, 2022',
    cover: 'https://miro.medium.com/max/1100/1*X6lOWsww3yxOY4VjU64U_g.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-texas-instruments-anish-mondal-d67807a3acfe',
  },
  {
    id: 17,
    title: 'DE Shaw | Vinit Raj',
    category: 'Software',
    company: 'DE Shaw',
    description:
      "Anyone targeting Software Roles should apply for the position also if they have interest in finance or to learn something new",
    createdAt: 'July 5, 2022',
    cover: 'https://miro.medium.com/max/1100/1*FtrQuat29QFgD2v7Ru829w.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-de-shaw-vinit-raj-fc9ae42e3f42',
  },
  {
    id: 18,
    title: 'Piramal | Sankalp Bose',
    category: 'FMCG',
    company: 'Piramal',
    description:
      "Piramal offers projects in the field of Business Scale using core engineering principles and Strategic Projects in the field of Logistics & Supply Chain",
    createdAt: 'July 4, 2022',
    cover: 'https://miro.medium.com/max/1100/1*PdvJIz1e72pF7HOK57lM-g.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-piramal-sankalp-bose-b81d194ed596',
  },
  {
    id: 19,
    title: 'Limechat | Tanaya Ramane',
    category: 'Product',
    company: 'Limechat',
    description:
      "I believe product roles for the company require two primary skills: Problem-Solving and Stakeholder Management",
    createdAt: 'July 2, 2022',
    cover: 'https://miro.medium.com/max/1100/0*VWHODfNs6QWhs28d',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-limechat-tanaya-ramane-a826ef79fedd',
  },
  {
    id: 20,
    title: 'Bajaj Auto | Sadha Shivam',
    category: 'Core',
    company: 'Bajaj Auto',
    description:
      "Anyone from core mechanical/electrical engineering should apply at Bajaj Auto and if you think you are good with the fundamentals of your branch",
    createdAt: 'June 30, 2022',
    cover: 'https://miro.medium.com/max/1100/1*TGO6h35rSADpx5r4Bhr0WA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-bajaj-auto-sadha-shivam-14736316f490',
  },
  {
    id: 21,
    title: 'Amazon | Sahil Shailesh Pedamkar',
    category: 'Software',
    company: 'Amazon',
    description:
      "Anyone who is interested in Software roles should apply for this job. There is no department barrier for application here",
    createdAt: 'June 29, 2022',
    cover: 'https://miro.medium.com/max/1100/1*6k-s0DLRiEoi3b8c0e5hUQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-amazon-sahil-shailesh-pedamkar-5d93117a0772',
  },
{
    id: 22,
    title: 'Sprinklr | Ayush Khatarkar',
    category: 'Software',
    company: 'Sprinklr',
    description:
      "All the technology enthusiasts and students who love coding should apply for this",
    createdAt: 'June 28, 2022',
    cover: 'https://miro.medium.com/max/1100/1*x4o8i5kkeX4z4pdTVHyqTw.webp',
    medium:'https://medium.com/@swgiitkgp/foresight-2022-placement-at-sprinklr-ayush-khatarkar-f24b68c92466 ',
  },
{
    id: 23,
    title: 'Goldman Sachs| Rishav Kumar',
    category: 'Software',
    company: 'Goldman Sachs',
    description:
      "People with interest in the Software Development domain with a slight interest in Finance should ideally for this internship",
    createdAt: 'June 25, 2022',
    cover: 'https://miro.medium.com/max/828/1*KuzVwPXKPP3y17lAvBveiw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-goldman-sachs-rishav-kumar-5d02a9f1169c',
  },
  {
    id: 24,
    title: 'Nvidia | Anubhav Prasad',
    category: 'Core',
    company: 'Nvidia',
    description:
      "People who love designing these complex devices that run HD video games, high computation etc.should definitely give it a shot",
    createdAt: 'June 20, 2022',
    cover: 'https://miro.medium.com/max/828/0*xvYlzEepLHVL3xbm',
    medium:'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-nvidia-anubhav-prasad-de29bda7e684 ',
  },
{
    id: 25,
    title: 'Bajaj Auto| Suryadeep Mandal',
    category: 'Core',
    company: 'Bajaj Auto',
    description:
      "People interested in core electrical engineering and automotive systems should apply for this job",
    createdAt: 'June 15, 2022',
    cover: 'https://miro.medium.com/max/828/1*fgdTt18Lsjp5AJcncJepew.webp',
    medium:'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-bajaj-auto-suryadeep-mandal-490ca180faeb ',
  },
{
    id: 26,
    title: 'Sprinklr | Nuruddin Jiruwala',
    category: 'Product Engineering',
    company: 'Sprinklr',
    description:
      "You can state in resume which specific role in Software company you want and if you like algorithms then surely go for it",
    createdAt: 'June 10, 2022',
    cover: 'https://miro.medium.com/max/828/0*NLiFb5RyJHWZdgmz',
    medium:'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-sprinklr-nuruddin-jiruwala-9ec1b9100105 ',
  },
 {
    id: 27,
    title: 'Honeywell | Aman Mishra',
    category: 'Software',
    company: 'Honeywell',
    description:
      "Anyone interested in Software Development can ideally apply for this job",
    createdAt: 'June 6, 2022',
    cover: 'https://miro.medium.com/max/1400/1*tBd9o6QmZP1jlTf-PDqi4w.webp',
    medium:'https://medium.com/@swgiitkgp/foresight-2022-summer-internship-at-honeywell-aman-mishra-14df567703f7 ',
  },
 {
    id: 28,
    title: 'Oracle | Arijit Ghosh',
    category: 'Software',
    company: 'Oracle',
    description:
      "Anyone who is keen to work for a big tech company can apply for this job",
    createdAt: 'Aug 4, 2022',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium:'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-oracle-arijit-ghosh-655e1ef1d64 ',
  },
 {
    id: 29,
    title: 'HoneyWell | Pritam Mallick',
    category: 'Software',
    company: 'HoneyWell',
    description:
      "Candidates who like to work on industrial problems expecting ethical treatment and respect of personal time can apply here",
    createdAt: 'August 4, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: "https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-honeywell-pritam-mallick-26e218ca4f96",
  },
{
    id: 30,
    title: 'American Express | Rishi Jain',
    category: '',
    company: 'American Express',
    description:
      "As the name 'Amex Strategy' suggests, they offer strategy consultant role, which involves working with a great team of analysts and managers",
    createdAt: 'July 29, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-american-express-rishi-jain-6610fdbf080a',
  },
 {
    id: 31,
    title: ' KPIT Technologies Ltd | Keshav Bagri',
    category: 'Software',
    company: 'KPIT Technologies Ltd',
    description:
      "If you are interested in the automotive domain and engineering, then you should definitely apply for this internship",
    createdAt: 'July 29, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-kpit-technologies-ltd-keshav-bagri-fc914197385b',
  },
 {
    id: 32,
    title: 'Microsoft | Pratyush Saha',
    category: 'Data',
    company: 'Microsoft',
    description:
      "Anyone with interest in Data Science and having considerable knowledge in the field of ML/DL with a decent level of coding skills can apply.",
    createdAt: 'July 29, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-microsoft-pratyush-saha-56d8322b9c61',
  },
{
    id: 33,
    title: 'Bajaj Auto |Swarnim Raj',
    category: 'Core',
    company: 'Bajaj Auto',
    description:"People who are into Electrical core profile and Electrical systems designing are well suited for the internship in Bajaj Auto",
    createdAt: 'June 29, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-bajaj-auto-swarnim-raj-a014bf31cf6b',
  },
{
    id: 34,
    title: 'Piramal Pharma | Aravind Barla',
    category: 'Core',
    company: 'Piramal Pharma',
    description:
      "Any potential Chemical Engineering student with a genuine interest in either core ChE or Supply Chain profiles should give their best try",
    createdAt: 'July 28, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-piralmal-pharma-aravind-barla-588916490414',
  },
{
    id: 35,
    title: 'Adobe | Aditi Singhania',
    category: 'Software',
    company: 'Adobe',
    description:
      "Anyone who's into machine learning and deep learning and wants to innovate something in this field must apply for this field",
    createdAt: 'July 28, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar2.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-adobe-aditi-singhania-44960e6f1ed8',
  },
{
    id: 36,
    title: 'Micron Technology | Rohit',
    category: 'Core',
    company: 'Micron Technology',
    description:
      "Micron is a semiconductor company. Those who are into core elctronics and want a career in the same should apply at Micron.",
    createdAt: 'July 28, 2021',
    cover: 'https://miro.medium.com/max/1100/1*NsvEran1St2dvTd-0nlKMA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-micron-technology-rohit-82e17503acec',
  },
{
    id: 37,
    title: 'Optum | Shubham Ekapure',
    category: 'Product',
    company: 'Optum',
    description:
      "If you have worked on AI and possess interest in business strategy, this role would be perfect for you.",
    createdAt: 'July 28, 2021',
    cover: 'https://miro.medium.com/max/1100/1*fwcNIuR1TQSI4EnUPOW1WQ.webp',
    medium:'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-optum-shubham-ekapure-c6d906164730 ',
  },
{
    id: 38,
    title: 'OYO | Alok Munshi',
    category: 'Analyst',
    company: 'OYO',
    description:
      "This fast-paced and growing organization gives you an oppurtunity to own and deliver end-to-end projects.",
    createdAt: 'July 28, 2021',
    cover: 'https://miro.medium.com/max/412/1*BIAvdcTzi1WGxcHzF_9qAQ.webp',
    medium:  'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-oyo-alok-munshi-77e62c227c0f',
  },
{
    id: 39,
    title: 'Qualcomm | Ankur Mishra',
    category: 'Core',
    company: 'Qualcomm',
    description:
      "People genuinely into Hardware and finding core subjects interesting can apply at Qualcomm.",
    createdAt: 'July 25, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium:'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-qualcomm-ankur-mishra-419441175f64 ',
  },
{
    id: 40,
    title: 'Suisse | Anshul Tembhare',
    category: 'Finance',
    company: 'Suisse',
    description:
      "The role here is suited for person interested in Finance and Statistics.",
    createdAt: 'July 25, 2021',
    cover: 'https://miro.medium.com/max/1100/1*by5k-7ddZ9sOaLHHRQmIfA.webp',
    medium: 'https://medium.com/@swgiitkgp/brief-introduction-b9f17d9c6a0',
  },
{
    id: 41,
    title: 'Nvidia | Ayan Chakraborty',
    category: 'Core',
    company: 'Nvidia',
    description:
      "Students wanting a career in core electronics and about work of big designs can apply at Nvidia",
    createdAt: 'July 25, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-nvidia-ayan-chakraborty-cda0b00566c1',
  },
{
    id: 42,
    title: 'Amazon | Soumitra Das',
    category: 'Software',
    company: 'Amazon',
    description:
     "People who want career in Software Development and having a interest in DSA can apply for Amazon",
    createdAt: 'July 25, 2021',
    cover: 'https://miro.medium.com/max/1100/1*fD6Jn8ZG0yMX0SEF81ZUxw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-amazon-soumitra-das-1523cd3470f4',
  },
{
    id: 43,
    title: 'Salesforce | Abhijay',
    category: 'Software',
    company: 'Salesforce',
    description:
      "For anyone who wants exposure of working at a Tech firm and likes to solve algorithmic problems can apply here",
    createdAt: 'July 23, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-salesforce-abhijay-cae5861fbc57',
  },
{
    id: 44,
    title: 'Goldman Sachs | Hardik Tibrewal',
    category: 'Software',
    company: 'Goldman Sachs',
    description:
      "This has software-oriented and finance-oriented.You can present your preference but final decision will be upto the firm",
    createdAt: 'July 23, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-goldman-sachs-hardik-tibrewal-76222447aae2',
  },
{
    id: 45,
    title: 'Deutsche Bank | Karmanya G ',
    category: 'Finance',
    company: 'Deutsche Bank',
    description:
      "Anyone interested in Data Science and having considerable knowledge in field of ML/DL with optimum coding skills can apply here",
    createdAt: 'July 23, 2021',
    cover: 'https://media.licdn.com/dms/image/C5603AQFrHXXx1n0Msg/profile-displayphoto-shrink_400_400/0/1627802398850?e=1677715200&v=beta&t=6j7eLuoHhyuQ13A-Zlh9nBM9W6ZJE44TDNc3OzoUEQI',
    medium:'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-at-deutsche-bank-karmanya-g-b-48baa5aa9671 ',
  },
{
    id: 46,
    title: 'JP Morgan Chase | Pratyasha',
    category: 'Finance',
    company: 'JP Morgan',
    description:
      "Those who are into Fintech and financial markets and interest in OOP and DSA can apply at JPMC(Markets)",
    createdAt: 'July 23, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar2.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2021-summer-internship-jp-morgan-chase-pratyasha-8c76aa30ba8b ' ,
  },
{
    id: 47,
    title: 'Microsoft | Shyamvanshikumar Singh',
    category: 'Software',
    company:'Microsoft',
    description:
      "All coding and technology enthusiasts can apply for a role at Microsoft.",
    createdAt: 'July 23, 2021',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2020-summer-internship-microsoft-shyamvanshikumar-singh-69034f8ce699',
  },
{
    id: 48,
    title: 'Mathworks | Tanmay Das',
    category: 'Software',
    company: 'Mathworks',
    description:
      "Anyone who's into any of hardware, software, image processing, computer vision or DL can apply at Mathworks for an internship",
    createdAt: 'September 18, 2020',
    cover: 'https://miro.medium.com/max/1100/0*Wwla4PsesWyOswc5',
    medium: 'https://medium.com/@swgiitkgp/foresight-2020-summer-internship-mathworks-tanmay-das-b06a73400f0d',
  },
{
    id: 49,
    Title:'ZestMoney | Shalina Kumari ',
    category: 'Product',
    company: 'ZestMoney',
    description:
      "To apply at ZestMoney prepare case-studies, guesstimates and subjects of finance or product-management a month prior",
    createdAt: 'Sep 17, 2020',
    cover: 'https://miro.medium.com/max/750/1*PWRKI4Kl_GugRUamPI7RgA.webp',
    medium:'https://medium.com/@swgiitkgp/foresight-2020-summer-internship-zestmoney-shalina-kumari-66435319d12d ',
  },
{
    id: 50,
    title: ' Dr Reddy’s laboratories | Anmol Joshi',
    category: 'Core',
    company: 'Dr Reddy’s laboratories',
    description:
      "Anyone who is intersted in core and has followed ChE curriculum of 2nd and 3rd year can apply here",
    createdAt: 'Sep 15, 2020',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium:'https://medium.com/@swgiitkgp/foresight-2020-summer-internship-dr-reddys-laboratories-anmol-joshi-184e5460879d',
  },
{
    id: 51,
    title: 'Goldman Sachs | Anubhav Jain',
    category: 'Securities',
    company: 'Goldman Sachs',
    description:
      "All the technology enthusiasts/coding geeks/finance loving  peeps can put their trump cards to get in here",
    createdAt: 'Aug 30, 2020',
    cover: 'https://miro.medium.com/max/828/1*SJb8VyC0zKVlwoWlgI_a1Q.webp',
    medium:'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-goldman-sachs-anubhav-jain-118c35b6f3f9 ',
  },
{
    id: 52,
    title: 'Goldman Sachs | Archit Tekriwal',
    category: 'Software',
    company: 'Goldman Sachs',
    description:
      "This company has no restrictions as to who can apply but is best suited for people wanting a career in finance",
    createdAt: 'Aug 30, 2020',
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium:'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-goldman-sachs-archit-tekriwal-e6ddcddacec7',
  },
 {
    id: 53,
    title: 'Capital One | Harsh Raj',
    category: 'Analyst',
    company: 'Capital One',
    description: "Anyone with agood analytical mind and having problem-solving skills can apply for this company",
    createdAt: "Aug 30, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-capital-one-harsh-raj-4fa05feacd8',
  },
 {
    id: 54,
    title: ' ITC Limited | Harsh Saraf',
    category: 'FMCG',
    company: 'ITC Limited',
    description: "If you want a first-hand experience of industrial/factory training in a extreme time crunch, then ITC offers a great oppurtunity.",
    createdAt: "Aug 29, 2020",
    cover: 'https://miro.medium.com/max/720/0*374Kz1Gu9rimpip8',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-itc-limited-harsh-saraf-c1be2a43059b',
  },
{
    id: 55,
    title: 'Microsoft | Sayan Mandal',
    category: 'Software',
    company:'Microsoft',
    description: "Anyone who loves CS, coding and wants to work in big tech company should definitely apply for this internship.",
    createdAt: "Aug 29, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-microsoft-sayan-mandal-9c574b6ddb33',
  },
{
    id: 56,
    title: 'Axis Bank | Sharad Kumar Saini',
    category: 'Finance',
    company: 'Axis Bank',
    description: "For the people who are interested in the business side of the bank and data analysis.",
    createdAt: "Aug 29, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-axis-bank-sharad-kumar-saini-68a5aee50e49',
  },
{
    id: 57,
    title: ' RIL-DMD | Shravesh Jain',
    category: 'Software',
    company: ' RIL-DMD',
    description: "People looking for a proper industrial experience in core engineering should go for it.",
    createdAt: "Aug 29, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-ril-dmd-shravesh-jain-d7eb0a8548d8',
  },
{
    id: 58,
    title: 'CITI, Pune | Shubhra Agrawal ',
    category: 'Software',
    company: 'CITI, Pune',
    description: "Anyone who wants an insight into the banking sector and who enjoys coding, the internship at CITI is a great experience.",
    createdAt: "Aug 29, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar2.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-citi-pune-shubhra-agrawal-3d4588a05a6c',
  },
{
    id: 59,
    title: 'Credit Suisse | Shweta Gupta',
    category: 'Markets',
    company: 'Credit Suisse',
    description: "Anyone who's looking to explore avenues in Quantitative Finance should apply for this internship.",
    createdAt: "Aug 29, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar2.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-credit-suisse-shweta-gupta-7bf292f20531',
  },
{
    id: 60,
    title: 'Schlumberger Software Technology Centre | Yashasvi Kanchugantla',
    category: 'Software',
    company: 'Schlumberger',
    description: "It's a place that can accommodate anyone but if you want some research experience, you shouldn't aim this.",
    createdAt: "Aug 29, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar2.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-schlumberger-software-technology-centre-yashasvi-kanchugantla-1d1e0d4abe88',
  },
{
    id: 61,
    title: 'Samsung Research Institute, Noida | Satyam Sevanya',
    category: 'Software',
    company: 'Samsung Research Institute, Noida',
    description: "Those who want to work for android development or mobile industry can go for this type of internship.",
    createdAt: "Aug 6, 2020",
    cover: 'https://miro.medium.com/max/1100/1*1jo74026e5nJD6czSTbgdA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-samsung-research-institute-noida-satyam-sevanya-f2a5c2349745',
  },
{
    id: 62,
    title: ' Credit Suisse | Rajdeep Biswas',
    category: 'Finance',
    company: 'Credit Suisse',
    description: "If you're into how a private and investment bank works and how they use technologies for security, then it's the apt place.",
    createdAt: "Aug 5, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-credit-suisse-rajdeep-biswas-be974eb3faf2',
  },
{
    id: 63,
    title:  'Texas Instruments | Ritwika Chowdhury',
    category: 'Software',
    company: 'Texas Instruments',
    description: "People who really like coding should apply for it.",
    createdAt: "Aug 5, 2020",
    cover: 'https://miro.medium.com/max/828/1*GT7zT04nRW1Xy9Tn817fhA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-texas-instruments-ritwika-chowdhury-73ae50e0925d',
  },
{
    id: 64,
    title: 'VISA Inc. | Shashwat Mishra',
    category: 'Software',
    company: 'VISA',
    description: "For anyone interested in learning technologies being implemented in Payments' processing should apply for it.",
    createdAt: "Aug 5 20, 2020",
    cover: 'https://miro.medium.com/max/828/1*R632tf1DmuMNnV32DYcGYw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-visa-inc-shashwat-mishra-19c0f10e0cc',
  },
{
    id: 65,
    title: ' Tower Research | Subham Rajgaria',
    category: 'Software',
    company: 'Tower Research',
    description:"Every CS student can apply for this as company has many divisions that can fit them.",
    createdAt: "Aug 5, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-tower-research-subham-rajgaria-57a0f7364a65',
  },
{
    id: 66,
    title: ' Flipkart | Arnab Bir',
    category: 'Software',
    company: 'Flipkart',
    description: "Those who love coding and want to work on real-world problems for India creating an impact should definitely apply.",
    createdAt: "Aug 5, 2020",
    cover: 'https://miro.medium.com/max/786/1*JgktgVnjLU4eOrB-8O-APQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2018-summer-internship-flipkart-arnab-bir-69ebc2acfa24',
  },
{
    id: 67,
    title: ' Credit Suisse | Shweta Jaiswal',
    category: 'Software',
    company: 'Credit Suisse',
    description: "All technology enthusiasts/coding geeks/finance loving people can surely apply.",
    createdAt: "Aug 5, 2020",
    cover: 'https://miro.medium.com/max/828/1*BpV4LFVZDGJfyL0WBD3FSQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-summer-internship-credit-suisse-shweta-jaiswal-3a2c93bbfd7d',
  },
{
    id: 68,
    title: 'American Express| Saloni Meshram',
    category: 'Data',
    company: 'American Express',
    description: "Students from all departments are eligible to apply. There was no CGPA cutoff.",
    createdAt: "Aug 5, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar2.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-summer-internship-american-express-saloni-meshram-18127f423821',
  },
{
    id:69,
    title: 'Deutsche Bank Center(DBC) | Chakit Chawda',
    category: 'Quant',
    company: 'Deutsche Bank',
    description: "Anyone who wants to give investment banking a try, can go for it.",
    createdAt: "Aug 5, 2020",
    cover: 'https://miro.medium.com/max/828/1*pjr3lsILu-tWE4yGYlauiA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-summer-internship-deutsche-bank-center-dbc-chakit-chawda-889f500c9bed',
  },
{
    id: 70,
    title: 'Goldman Sachs | G.Vishal',
    category: 'Finance',
    company: 'Goldman Sachs',
    description: "People who like math, quant and have an interest in financial services sector should definitely apply for this internship.",
    createdAt: "Aug 5, 2020",
    cover: 'https://miro.medium.com/max/640/1*ILNF37uxCEqrHp1ZjZRSgA.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-summer-internship-goldman-sachs-g-vishal-4cff2feeae45',
  },
{
    id: 71,
    title: 'Deutsche Bank | Indresh',
    category: 'Finance',
    company: 'Deutsche Bank',
    description: "Everyone and anyone can appky for this company if they have a well-drafted CV.",
    createdAt: "Aug 5, 2020",
    cover: 'https://miro.medium.com/max/640/1*Shj2pOro0USuACPfTD-BgQ.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-summer-internship-deutsche-bank-indresh-66bf51485a64',
  },
{
    id: 72,
    title: 'Nomura | Pallav Bhutani',
    category: 'Finance',
    company: 'Nomura',
    description:"Anyone and everyone can definitely apply for this internship.",
    createdAt: "Aug 5, 2020",
    cover: 'https://miro.medium.com/max/828/1*SFbZAZC9P5UEPRWf20Ncxg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-summer-internship-nomuras-global-markets-pallav-bhutani-ddc09b7fe479',
  },
{
    id: 73,
    title: ' Nomura Structured Financial Services | Amandeep Singh',
    category: 'Finance',
    company: 'Nomura',
    description: "This internship is perfect for anyone wanting to know how the finance industry operates,no matter one has prior knowledge in finance.",
    createdAt: "Aug 4, 2020",
    cover: 'https://miro.medium.com/max/828/1*oDfPd_ZibVbgWy8OavbmJg.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-summer-internship-nomura-structured-financial-services-amandeep-singh-ac646b51f58',
  },
{
    id: 74,
    title: 'Deutsche Bank | Aditi Kambli',
    category: 'Global Markets',
    company: 'Deutsche Bank',
    description:"DB selection process doesn't require you to have a prior knowledge in finance.They look for what you have excelled in past.",
    createdAt: "Aug 4, 2020",
    cover: 'https://www.w3schools.com/howto/img_avatar2.png',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-summer-internship-deutsche-bank-global-markets-aditi-kambli-833542c0d8a9',
  },
{
    id: 75,
    title: 'Tower Research Capital | Spandan Kumar Sahu',
    category: 'Software',
    company: 'Tower Research',
    description: "Tower exclusively looks for CS majors with about 9.5 CG or be good in something specific they are looking for.",
    createdAt: "Aug 4, 2020",
    cover: 'https://miro.medium.com/max/828/1*XNDYZ5rU-8Xqxqw9CBh1qw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-summer-internship-tower-research-capital-spandan-kumar-sahu-7e62b89dfd7',
  },
{
    id: 76,
    title: 'ITC | Rajat Goel',
    category: 'FMCG',
    company: 'ITC ',
    description: "For someone wanting know how a corporate life is and how big organizations operates, can surely apply.",
    createdAt: "Aug 4, 2020",
    cover: 'https://miro.medium.com/max/828/1*VY5lYJxY7vfHF00CseK3Gw.webp',
    medium: 'https://medium.com/@swgiitkgp/foresight-2019-20-summer-internship-itc-rajat-goel-6863f1afd549',
  },
];
    
