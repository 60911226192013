import React from 'react'
import './EmptyListStyles.css'

const EmptyList = () => {
  return (
    <div className='emptyList-wrap'>
        <img src='/assets/images/13525-empty.gif' alt="empty"/>
    </div>
  )
}

export default EmptyList