import React from 'react'
import "./style.css"

const Credits = () => {
  return (
    <div className='credits'>
    </div>
  )
}

export default Credits