import React from 'react'
import "./style.css"

const Header5 = () => {
  return (
    <div className='home-header5'>
        <h2>
            2nd Year
        </h2>
        <h1>
            <span>
                </span>Resources<span>
            </span>
        </h1>
    </div>
  )
}

export default Header5
