import React from 'react'
import "./style.css"

const Header4 = () => {
  return (
    <div className='home-header'>
        <h2>
            Know Your
        </h2>
        <h1>
            <span>
                </span> Department <span>
            </span>
        </h1>
        <p>
            Series of blogs about all Departments<br/>
        </p>
    </div>
  )
}

export default Header4
