export const SMList = [
    {
        id: 1,
        name: 'Akshat Kapoor',
        designation: 'Tech Head',
        fb: 'https://www.facebook.com/akshat.kapoor.9461?mibextid=ZbWKwL',
        instagram: '',
        linkedin:'https://www.linkedin.com/in/akshat-kapoor-7955b6259',
        pfp: '/assets/images/akshat.jpg',
      },
      {
        id: 2,
        name: 'Astha Kumari',
        designation: 'Executive Head',
        fb: 'https://www.facebook.com/profile.php?id=100087299360656&mibextid=ZbWKwL',
        instagram: ['frontend', 'ui/ux', 'design'],
        linkedin:'https://www.linkedin.com/in/astha-kumari-980170259',
        pfp: '/assets/images/astha.jpg',
      },
      {
        id: 3,
        name: 'Astitva Srivastava',
        designation: 'Core Head',
        fb: 'https://www.facebook.com/profile.php?id=100088417697358&mibextid=ZbWKwL',
        instagram: ['frontend', 'ui/ux', 'design'],
        linkedin:'https://www.linkedin.com/in/astitvaprofileview?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
        pfp: '/assets/images/astitva.jpg',
      },
      {
        id: 4,
        name: 'Bhawesh Kukreja',
        designation: 'Design Head',
        fb: 'https://www.facebook.com/bhawesh.kukreja.5?mibextid=ZbWKwL',
        instagram: ['frontend', 'ui/ux', 'design'],
        linkedin:'https://www.linkedin.com/in/bhawesh-kukreja-430a46257',
        pfp: '/assets/images/bhawesh.jpg',
      },
      {
        id: 5,
        name: 'Shashank Tiwari',
        designation: 'Executive Head',
        fb: 'https://www.facebook.com/profile.php?id=100087904082305&mibextid=ZbWKwL',
        instagram: ['frontend', 'ui/ux', 'design'],
        linkedin:'https://www.linkedin.com/in/shashank-tiwari-9b16a724b',
        pfp: '/assets/images/shashank.jpg',
      },
      {
        id: 6,
        name: 'Shivam Bisen',
        designation: 'Videography Head',
        fb: 'https://www.facebook.com/profile.php?id=100088844135102&mibextid=ZbWKwL',
        instagram: ['frontend', 'ui/ux', 'design'],
        linkedin:'https://www.linkedin.com/in/shivam-bisen-812927250',
        pfp: '/assets/images/shivam.jpg',
      },
      {
        id: 7,
        name: 'Shravan Barad',
        designation: 'Core Head',
        fb: 'https://www.facebook.com/shravan.barad',
        instagram: ['frontend', 'ui/ux', 'design'],
        linkedin:'https://www.linkedin.com/in/shravan-barad-290923257?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
        pfp: '/assets/images/shravan.jpg',
      },
      {
        id: 8,
        name: 'Vumika Ghosh',
        designation: 'PR/Lit Head',
        fb: 'https://www.facebook.com/profile.php?id=100088553164652',
        instagram: '',
        linkedin:'https://www.linkedin.com/in/vumika-ghosh-b89463258?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
        pfp: '/assets/images/vumika.jpg',
      },
  ];